import ListItem, { IListItem } from "./ListItem";
import { Box } from "@chakra-ui/react";

interface Props {
  data: IListItem[];
  filter: string;
  loading?: boolean;
}

const List = ({ data, loading, filter }: Props) => {
  const filteredData = () => {
    return data.filter((el) =>
      el.address.toLowerCase().includes(filter.toLowerCase().trim())
    );
  };

  return (
    <Box mt={10} className="list">
      {filteredData()
        .reverse()
        .map((element) => (
          <ListItem loading={loading} data={element} />
        ))}
    </Box>
  );
};

export default List;
