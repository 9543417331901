import { useState, useEffect, useRef } from "react";
// @ts-ignore
import DOTS from "vanta/dist/vanta.dots.min";
import "./style.css";

export const Background = ({ currentTheme }: { currentTheme: string }) => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      createBackground();
    }
    return () => {
      // @ts-ignore
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  useEffect(() => {
    // @ts-ignore
    if (vantaEffect) vantaEffect.destroy();
    createBackground();
  }, [currentTheme]);

  const createBackground = () => {
    setVantaEffect(
      DOTS({
        el: myRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.0,
        minWidth: 200.0,
        scale: 1.0,
        scaleMobile: 1.0,
        backgroundColor: currentTheme === "dark" ? 0x1a202c : 0xffffff,
      })
    );
  };

  return (
    <div className="animated-background" ref={myRef}>
      <div className="hover-shade"></div>
    </div>
  );
};
