import * as React from "react";
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
  Container,
  IconButton,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import List from "./List";
import { useState } from "react";
import { useEffect } from "react";
import "./style.css";
import { Background } from "./Background";
import GithubIcon from "./github-brands.svg";
import DiscordIcon from "./discord-brands.svg";
import Header from "./Header";

export const App = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("chakra-ui-color-mode") || "dark"
  );
  const [filter, setFilter] = useState("");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(
      "https://raw.githubusercontent.com/nerdvibe/mina-addresses-blacklist/main/addresses.json"
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setTimeout(() => {
          setLoading(false);
        }, 250);
      });
  };

  return (
    <ChakraProvider
      theme={{
        ...theme,
        config: {
          initialColorMode: "dark",
        },
      }}
    >
      <Background currentTheme={currentTheme} />

      <Box textAlign="center" fontSize="xl">
        <Grid p={3} zIndex={10}>
          <div className="icons">
            <a
              href="https://discord.gg/4GnkRqwsDK"
              target={"_blank"}
              rel="noreferrer"
            >
              <IconButton
                size="sm"
                fontSize="lg"
                variant="ghost"
                color="current"
                marginLeft="2"
                icon={
                  <img
                    className="github-icon"
                    src={DiscordIcon}
                    alt="Discord icon"
                  />
                }
                aria-label={`Go to discord`}
              />
            </a>
            <a
              href="https://github.com/nerdvibe/mina-addresses-blacklist"
              target={"_blank"}
              rel="noreferrer"
            >
              <IconButton
                size="sm"
                fontSize="lg"
                variant="ghost"
                color="current"
                marginLeft="2"
                icon={
                  <img
                    className="github-icon"
                    src={GithubIcon}
                    alt="Github icon"
                  />
                }
                aria-label={`Go to github`}
              />
            </a>
            <ColorModeSwitcher
              justifySelf="flex-end"
              currentTheme={currentTheme}
              setCurrentTheme={setCurrentTheme}
            />
          </div>
          <Container maxW={"825px"}>
            <Header filter={filter} setFilter={setFilter} />
            <div className="relative">
              <List data={data} loading={loading} filter={filter} />
            </div>
          </Container>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};
