import { Search2Icon } from "@chakra-ui/icons";
import {
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import Sticky from "react-sticky-el";

interface Props {
  filter: string;
  setFilter: (text: string) => void;
}

const Header = ({ filter, setFilter }: Props) => {
  return (
    <div className="z-index-100">
      <Sticky>
        <div className="sticky-header">
          <Flex className="main-header">
            <Center width="80% ">
              <div>
                <Heading>Mina Blacklist</Heading>
                <Text>
                  An opensource list of phishing addresses for the
                  <br /> Mina Protocol Blockchain - <a
              className="link-text"
              href="https://raw.githubusercontent.com/nerdvibe/mina-addresses-blacklist/main/addresses.json"
              target={"_blank"}
              rel="noreferrer"
            >API</a>
                </Text>
              </div>
            </Center>
          </Flex>
          <InputGroup pb={5}>
            <InputLeftElement
              pointerEvents="none"
              children={<Search2Icon color="gray.300" />}
            />
            <Input
              type="text"
              placeholder="Search address..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
          <Text className="report-text">
            Found an address that is not in the list?{" "}
            <a
              className="link-text"
              href="https://github.com/nerdvibe/mina-addresses-blacklist/issues/new?assignees=&labels=&template=scam-address-report.md&title=Report+%7BREPLACE_WITH_ADDRESS%7D"
              target={"_blank"}
              rel="noreferrer"
            >
              Report an address
            </a>
          </Text>
          <Text className="report-text smaller-text">
            This website is maintained by{" "}
            <Link
              href="https://discord.gg/4GnkRqwsDK"
              color={"white"}
              target={"_blank"}
              rel="noreferrer"
            >
              <Tooltip
                placement="bottom"
                hasArrow
                label={"Get in touch with us on Discord"}
              >
                WeStake.Club⚡️
              </Tooltip>
            </Link>
          </Text>
        </div>
      </Sticky>
    </div>
  );
};

export default Header;
