import {
  Center,
  Flex,
  SkeletonCircle,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import Avatar from "./Avatar/Avatar";

export interface IListItem {
  address: string;
  info: string;
}

interface Props {
  loading?: boolean;
  data: IListItem;
}

const ListItem = ({ loading, data }: Props) => {
  return (
    <>
      <Flex key={data.address} mt={2} mb={2} className="list-item">
        {" "}
        <Center w={"60px"} h={"60px"}>
          <SkeletonCircle mr={4} height={"40px"} size="40" isLoaded={!loading}>
            <Avatar address={data.address} size="40" />
          </SkeletonCircle>
        </Center>
        <SkeletonText
          isLoaded={!loading}
          noOfLines={2}
          spacing="5"
          mt={loading ? 3 : 0}
        >
          <strong>
            <Text align={"left"}>{data.address}</Text>
          </strong>
          <Text align={"left"}>{data.info}</Text>
        </SkeletonText>
      </Flex>
      {!loading && <hr />}
    </>
  );
};

export default ListItem;
